import {html} from "lit";
import {Base} from "./Base";
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js'

class ValidationButtons extends Base {

    discard() {
        this.dispatchEvent(new CustomEvent('discard'))
    }

    submit() {
        this.dispatchEvent(new CustomEvent('submit'))
    }

    render() {
        return html`
            <div class="buttons">
                <sl-icon-button @click=${this.discard} style="color:#dd0000; font-size: 3.5rem;" name="x-circle"
                                label="discard"></sl-icon-button>
                <sl-icon-button @click=${this.submit} style="color:#00dd00; font-size: 3.5rem;"
                                name="check-circle"
                                label="submit"></sl-icon-button>
            </div>
        `
    }
}

customElements.define('validation-buttons', ValidationButtons)
