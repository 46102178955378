import {LocalStorageBucket} from "@orbit/local-storage-bucket";
import {RecordSchema} from "@orbit/records";
import {MemorySource} from "@orbit/memory";
import {IndexedDBSource} from "@orbit/indexeddb";
import {Coordinator, SyncStrategy} from "@orbit/coordinator";

const bucket = new LocalStorageBucket({namespace: 'fjms'});

// import { JSONAPISource } from '@orbit/jsonapi';
// const remote = new JSONAPISource({
//     schema,
//     name: 'remote',
//     host: 'http://api.example.com'
// });
// coordinator.addSource(remote);
// coordinator.addStrategy(
//     new RequestStrategy({
//         source: 'memory',
//         on: 'beforeQuery',
//         target: 'remote',
//         action: 'query',
//         blocking: false
//     })
// );
// coordinator.addStrategy(
//     new RequestStrategy({
//         source: 'memory',
//         on: 'beforeUpdate',
//         target: 'remote',
//         action: 'update',
//         blocking: false
//     })
// );
// coordinator.addStrategy(
//     new SyncStrategy({
//         source: 'remote',
//         target: 'memory',
//         blocking: false
//     })
// );

const schema = new RecordSchema({
    models: {
        category: {
            attributes: {
                name: {type: 'string'},
                color: {type: 'string'},
            },
            relationships: {
                cards: {type: 'card', kind: 'hasMany', inverse: 'categories'},
            }
        },
        card: {
            attributes: {
                createdAt: {type: 'datetime'},
                nextShow: {type: 'datetime'},
                validated: {type: 'boolean'},
                photo: {type: 'string'},
                crop: {type: 'string'},
                html: {type: 'string'},
                drawing: {type: 'string'},
            },
            relationships: {
                categories: {type: 'category', kind: 'hasMany', inverse: 'cards'},
            }
        }
    }
});

export const memory = new MemorySource({bucket, schema});

const backup = new IndexedDBSource({
    schema,
    name: 'backup',
    namespace: 'fjms',
    defaultTransformOptions: {useBuffer: true},
});

const coordinator = new Coordinator({
    sources: [memory, backup]
});

const backupMemorySync = new SyncStrategy({
    source: 'memory',
    target: 'backup',
    blocking: true
});

coordinator.addStrategy(backupMemorySync);

const allRecords = await backup.query((q) => q.findRecords());
await memory.sync((t) => allRecords.map((r) => t.addRecord(r)));
await coordinator.activate();
