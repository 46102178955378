import {LitElement} from "lit";

export class Base extends LitElement {

    //!\ sans shadowRoot, les composants ne peuvent pas se styliser eux mêmes.
    //   il faut les styliser globalement dans app-index
    createRenderRoot() {
        return this
    }
}
