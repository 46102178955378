import "urlpattern-polyfill"
import {html} from "lit";
import {memory} from "./orbit";

function mixinEmitter(obj) {
    ["addEventListener", "dispatchEvent", "removeEventListener"]
        .forEach((method) => obj[method] = window[method].bind(window))
}

/**
 * @method {*} addEventListener()
 * @method {*} removeEventListener()
 * @method {*} dispatchEvent()
 * @extends HTMLElement
 */
class Router {

    routes = []
    #fallback = '/';

    constructor() {
        mixinEmitter(this);
    }

    get url() {
        return window.location.hash.substring(1)
    }

    #load = () => {
        this.route(this.url)
    }

    #hashchange = () => {
        this.route(this.url)
    }

    route(url) {
        url = url || this.#fallback
        if (!(url instanceof URL)) {
            url = new URL(url, document.baseURI)
        }
        const route = this.find(url);
        if (route) {
            this.dispatchEvent(new CustomEvent('route', {detail: route}))
        } else {
            window.location.hash = `#${this.#fallback}`
        }
    }

    find(url) {
        const query = Object.fromEntries(new URLSearchParams(url.search));
        let match
        const route = this.routes.find(route => match = route.urlPattern.exec(url))
        if (match) {
            return {
                ...route,
                params: match.pathname.groups,
                query
            }
        }
    }

    enable() {
        window.addEventListener("load", this.#load);
        window.addEventListener("hashchange", this.#hashchange);
        this.#load()
        return this
    }

    disable() {
        window.removeEventListener("load", this.#load);
        window.removeEventListener("hashchange", this.#hashchange);
        return this
    }

    add(route) {
        // yarn add urlpattern-polyfill
        route.urlPattern = new URLPattern({pathname: route.url});
        this.routes.push({...route})
        return this
    }

    fallback(url) {
        this.#fallback = url
        return this
    }
}

export const router = window.router = new Router()

router.fallback('/home')
    .add({
        url: '/home',
        render: (/*route*/) => import('./page-home.js').then(() => html`
            <page-home></page-home>`)
    })
    .add({
        url: '/test',
        render: (/*route*/) => import('./page-test.js').then(() => html`
            <page-test></page-test>`)
    })
    .add({
        url: '/camera',
        render: (/*route*/) => import('./page-camera.js').then(() => html`
            <page-camera></page-camera>`)
    })
    .add({
        url: '/crop',
        render: (/*route*/) => import('./page-crop.js').then(() => html`
            <page-crop></page-crop>`)
    })
    .add({
        url: '/draw',
        render: (/*route*/) => import('./page-draw.js').then(() => html`
            <page-draw></page-draw>`)
    })
    .add({
        url: '/quill',
        render: (/*route*/) => import('./page-quill.js').then(() => html`
            <page-quill></page-quill>`)
    })
    .add({
        url: '/orbit',
        render: (/*route*/) => import('./page-orbit.js').then(() => html`
            <page-orbit></page-orbit>`)
    })
    .add({
        url: '/new/category',
        render: (/*route*/) => import('./page-new-category.js').then(() => html`
            <page-new-category></page-new-category>`)
    })
    .add({
        url: '/edit/category/:id',
        render: (route) => import('./page-edit-category.js')
            .then(() => memory.query((q) => q.findRecord({type: 'category', id: route.params.id})))
            .then((category) => html`
                <page-edit-category .category="${category}"></page-edit-category>`)
    })
    .add({
        url: '/new/card',
        render: (/*route*/) => import('./page-new-card.js').then(() => html`
            <page-new-card></page-new-card>`)
    })
    .add({
        url: '/edit/card/:id',
        render: (route) => import('./page-edit-card.js')
            .then(() => memory.query((q) => q.findRecord({type: 'card', id: route.params.id})))
            .then((card) => html`
                <page-edit-card .card="${card}"></page-edit-card>`)
    })
    .add({
        url: '/card/:id',
        render: (route) => import('./page-card.js')
            .then(() => memory.query((q) => q.findRecord({type: 'card', id: route.params.id})))
            .then((card) => html`
                <page-card .card="${card}"></page-card>`)
    })


export function goBack() {
    history.back()
}

export function goHome() {
    location.href = `#/home`
}

export function goTest() {
    location.href = `#/test`
}

export function goCamera() {
    location.href = `#/camera`
}

export function goCrop() {
    location.href = `#/crop`
}

export function goDraw() {
    location.href = `#/draw`
}

export function goQuill() {
    location.href = `#/quill`
}

export function goOrbit() {
    location.href = `#/orbit`
}

export function goNewCategory() {
    location.href = `#/new/category`
}

export function goEditCategory(category) {
    const id = typeof category === 'object' ? category.id : category
    location.href = `#/edit/category/${id}`
}

export function goNewCard() {
    location.href = `#/new/card`
}

export function goEditCard(card) {
    const id = typeof card === 'object' ? card.id : card
    location.href = `#/edit/card/${id}`
}

export function goCard(card) {
    const id = typeof card === 'object' ? card.id : card
    location.href = `#/card/${id}`
}
