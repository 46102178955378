import {LitElement} from 'lit';
import {router} from './router';
import {setBasePath} from '@shoelace-style/shoelace/dist/utilities/base-path.js';
import {until} from "lit/directives/until.js";
import './validation-buttons'

// import {gestures} from '@composi/gestures'
// gestures()

setBasePath('shoelace');

export class AppIndex extends LitElement {

    createRenderRoot() {
        return this
    }

    firstUpdated(_changedProperties) {
        this.classList.add('app')
        router.addEventListener('route', (e) => {
            this.route = e.detail
            if ("startViewTransition" in document) {
                return document.startViewTransition(() => {
                    this.requestUpdate();
                });
            } else {
                this.requestUpdate();
            }
        });
        router.enable()
    }

    render() {
        return until(this.route?.render(this.route));
    }
}

customElements.define('app-index', AppIndex)
